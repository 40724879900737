/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/main/docs/suggestions.md
 */
$(document).ready(function() {
  let columnNumber, divs;
  BeerMenus.infiniteScroll = new BeerMenus.InfiniteScroll();

  const scrollsection = $('.scroll-section');
  scrollsection.waypoint({
    handler(direction) {
      if (direction === 'down') {
        $(this.element).addClass('scroll-visible');
      } else {
        $(this.element).removeClass('scroll-visible');
      }
    },
    offset: '80%'
  });

  $('.sticky-table').stickyTableHeaders();

  // Facebook
  const statusChangeCallback = function(response) {
    const barID = $('#fb-pages').data('bar-id');
    const source = $('#fb-pages').data('source');
    if (response.status === 'connected') {
      const {
        accessToken
      } = response.authResponse;
      const authURL = '/facebook_connections/manage_page?bar_id=' + barID + '&at=' + accessToken + '&source=' + source;
      $.ajax({url: authURL});
    } else {
      requestManagePage();
    }
  };

  const handleRerequest = function(response) {
    const barID = $('#fb-pages').data('bar-id');
    const source = $('#fb-pages').data('source');
    if (response.status === 'connected') {
      statusChangeCallback(response);
    } else {
      const authURL = '/facebook_connections/manage_page?bar_id=' + barID + '&source=' + source;
      $.ajax({url: authURL});
    }
  };

  var requestManagePage = function() {
    FB.login((function(response) {
      FB.getLoginStatus(function(response) {
        handleRerequest(response);
      });
    }), {
      scope: 'pages_show_list,pages_manage_posts',
      auth_type: 'rerequest'
    }
    );
  };

  $('.user_beer_photo_default_checkbox').click(function() {
    $(this)[0].form.submit();
  });

  // Dropdowns
  $('html').on('click', 'body', function(e) {
    if ($('.dropdown-menu').is(":visible")) {
      if (!(e.target.className.indexOf("dropdown-trigger") > -1)) {
        return $(".dropdown-container").removeClass("show-dropdown");
      }
    }
  });

  window.timeoutClock = null;

  $('html').on('click', '.dropdown-trigger', function(e) {
    e.preventDefault();

    const container = $(this).parent();
    const dropdown = $(this).siblings('.dropdown-menu');
    if (dropdown.is(':visible')) {
      container.removeClass('show-dropdown');
    } else {
      $('.dropdown-container').removeClass('show-dropdown');
      container.addClass('show-dropdown');
    }
  });
  $('html').on('mouseleave', '.dropdown-menu', function() {
    const container = $(this).parent();
    window.timeoutClock = setTimeout((function() {
      container.removeClass('show-dropdown');
    }), 1000);
  }).on('mouseenter', '.dropdown-menu', function() {
    if (window.timeoutClock !== null) {
      clearTimeout(window.timeoutClock);
    }
  });

  $.fn.nextOrFirst = function(selector) {
    const next = this.next(selector);
    if (next.length) { return next; } else { return this.prevAll(selector).last(); }
  };

  // Geocode
  $(".content").on("submit", ".update-location", function() {
    $(this).find("input[type='submit']").prop("disabled", true);
    $(this).find("input[type='submit']").val($(".update-location .pure-button").data("disable-with"));
    load_gmaps_api("geocode");
    return false;
  });

  $("body").on("submit", ".geocode-form", function(event) {
    $(this).find("input[type='submit']").prop("disabled", true);
    $(this).find("input[type='submit']").val($(".geocode-form .pure-button").data("disable-with"));
    load_gmaps_api("geocodeForm");
    return false;
  });

  window.geocode = function() {
    BeerMenus.Analytics.track("Geocoding", {stack: "desktop/application.coffee#104"});
    const geocoder = new (google.maps.Geocoder);
    const address = $(".address").val();
    const original_label = $(".update-location .pure-button").data("original-label");

    return geocoder.geocode({ "address": address }, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        const result = results[0];
        $(".latitude").val(result.geometry.location.lat());
        $(".longitude").val(result.geometry.location.lng());
        $(".full_address").val(result.formatted_address);
        return $(".update-location")[0].submit();
      } else {
        $(".update-location").find("input[type='submit']").prop("disabled", false);
        $(".update-location").find("input[type='submit']").val(original_label);
        return alert("Whoops, we don't recognize that location. Please enter a full address, city, state, or zip.");
      }
    });
  };

  window.geocodeForm = function() {
    let address;
    BeerMenus.Analytics.track("Geocoding", {stack: "desktop/application.coffee#122"});
    const geocoder = new (google.maps.Geocoder);

    if ($(".address2").length > 0) {
      address = $(".address").val() + ' ' + $(".address2").val();
    } else {
      address = $(".address").val();
    }

    return geocoder.geocode({ "address": address }, function(results, status) {
      if (status === google.maps.GeocoderStatus.OK) {
        const result = results[0];
        if ($('.city-name').length > 0) {
          let city = '';
          let state = '';
          $.each(result.address_components, function(index, value) {
            if ($.inArray('locality', value.types) > -1) {
              return city = value.long_name;
            } else if ($.inArray('administrative_area_level_1', value.types) > -1) {
              return state = value.short_name;
            }
          });

          const city_name = city + ', ' + state;
          $('.city-name').val(city_name);
        }

        $('.latitude').val(result.geometry.location.lat());
        $('.longitude').val(result.geometry.location.lng());
        return $('.geocode-form')[0].submit();
      } else {
        return $('.geocode-form')[0].submit();
      }
    });
  };

  // Load gmaps js api
  var load_gmaps_api = function(callback) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    const key = process.env.GEOCODER_API_KEY;
    script.src = `//maps.googleapis.com/maps/api/js?key=${key}&callback=${callback}`;
    return document.body.appendChild(script);
  };

  // Authenticate
  bind_join_or_login_form();

  // First form input
  $('.first-input').focus();

  // Forgot password
  $('.content').on('click', '.forgot-password a', function() {
    $('.reset-password').toggle();
    return false;
  });

  // Follow
  $('.content').on('mouseover', '.following-label', function() {
    return $(this).text('Unfollow');
  }).on('mouseleave', '.following-label', function(event) {
    return $(this).text('Following');
  });

  // Pro and Pricing
  if ($('.x-pro-plans').length > 0) {
    columnNumber = $(this).find('input[type=radio]:checked').closest(".plan").index() + 1;
    divs = $(this).find('.pure-g div:nth-child(' + columnNumber + ')');
    divs.addClass('plan-selected');
    $('.plan-name').text(divs.find('.plan-radio').data('plan-name'));
  }

  $(".plans").on("click", ".plan", function() {
    $(".plan").removeClass("plan-selected");

    columnNumber = $(this).index() + 1;
    divs = $('.plans').find('.pure-g div:nth-child(' + columnNumber + ')');
    divs.find(':radio').prop('checked', true).change();
    divs.addClass('plan-selected');

    $('.plan-name').text(divs.find('.plan-radio').data('plan-name'));
    $('.monthly-price').text(divs.find('.plan-radio').data('monthly-price'));
    $('.annual-price').text(divs.find('.plan-radio').data('annual-price'));
    $('.annual-savings').text(divs.find('.plan-radio').data('annual-savings'));
    $('.monthly-plan').val(divs.find('.plan-radio').data('monthly'));
    $('.annual-plan').val(divs.find('.plan-radio').data('annual'));
  });

  $('.plans').on('mouseenter', '.plan', function() {
    columnNumber = $(this).index() + 1;
    divs = $('.plans').find('.pure-g div:nth-child(' + columnNumber + ')');
    divs.addClass('plan-hover');
  });

  $('.plans').on('mouseleave', '.plan', function() {
    $('.plan').removeClass('plan-hover');
  });

  // Cancel
  $('.x-cancel-membership').on('shown.bs.modal', function() {
    const bar_id = $(this).data("bar-id");
    const local_audience = $(this).data("local-audience");
    const radius = $(this).data("radius");
    return BeerMenus.Analytics.track("Viewed Cancel Pro Membership Modal",
      {group_id: bar_id, "Local Audience": local_audience, "Local Audience Radius": radius});
  });

  // Schedule call modal
  $('#walk-through').on('shown.bs.modal', function() {
    const ref = $(this).data("ref");
    return BeerMenus.Analytics.track("Viewed schedule call modal", {"Schedule call ref": ref});
  });

  $('#get-started').on('shown.bs.modal', function() {
    const ref = $(this).data("ref");
    return BeerMenus.Analytics.track("Viewed add business modal", {"Add business modal ref": ref});
  });

  // Facebook
  $('body').on('click', '.connect-fb-page', function() {
    FB.getLoginStatus(response => statusChangeCallback(response));
    return false;
  });

  $('body').on('click', '.connect-fb-page-rerequest', function() {
    requestManagePage();
    return false;
  });

  $('.content').on('change', '#serving_serving_type', function() {
    const serving_type = $(this).val();
    const kegs = [
      '19',
      '36',
      '20',
      '21',
      '22',
      '23',
      '38'
    ];
    const is_keg = $.inArray(serving_type, kegs);
    if (is_keg === -1) {
      $('.size').show();
    } else {
      $('.size').hide();
    }
  });

  $('.content').on('click', '#twitter-share', function() {
    $(this).prop('checked', false);
    return window.location = $('.twitter-auth').attr('href');
  });

  $('.content').on('click', '#fb-share', function() {
    $(this).prop('checked', false);
    return FB.getLoginStatus(response => statusChangeCallback(response));
  });

  // change menu if selected.val() == 5 or selected.text() == "Pint"
  $('.web').on('change', '.serving_unit', function() {
    const selected = $(this).children('option:selected');
    const measure_menu = $(this).parents('div.serving-size-options');
    const text_box = measure_menu.find('input.serving_size_text_box');
    const pint_select = measure_menu.find('select.serving_size_pint_select');
    const pour_select = measure_menu.find('select.serving_size_pour_select')

    if (selected.val() === "5") {
      text_box.hide().prop("disabled", true);
      pint_select.show().prop("disabled", false);
      return pour_select.hide().prop("disabled", true);
    } else if (selected.val() === "6") {
      text_box.hide().prop("disabled", true);
      pint_select.hide().prop("disabled", true);
      pour_select.show().prop("disabled", false);
    } else {
      text_box.show().prop("disabled", false);
      pint_select.hide().prop("disabled", true);
      return pour_select.hide().prop("disabled", true);
    }
  });

  // Onboarding
  $('.linear-onboard').on('click', '.choose-section', function() {
    $('.next-step-section').hide();
    $('.section-option').hide();
    $('.choose-section').parent('.pure-tabs-item').removeClass('pure-tabs-item-selected');
    const section = $(this).data('section-option');
    $(section).show();
    if (section === '#install_code') {
      $('.next-step-section').show();
    }
    $(this).parent('.pure-tabs-item').addClass('pure-tabs-item-selected');
    return false;
  });

  $('.linear-onboard').on('click', '.x-import-beers', function() {
    $('.x-import-beers').show();
    $('.x-import-beers-next').show();
    return false;
  });

  // Inline Errors
  if ($('.error-msg').length > 0) {
    $('.error-msg').each(function() {
      $(this).parent('div').addClass('has-error');
    });
  }

  if ($('#x_overlay').length > 0) {
    $($('#x_overlay').text()).click();
  }

  // Auto-upload files
  return $('.auto-upload').change(function() {
    $(this).closest('form').submit();
    $(this).prop('disabled', true);
    $('.upload-image-label').text('Uploading...');
  });
});

window.bind_join_or_login_form = function() {
  $(".modal, .content").on("click", ".x-show-join", function(e) {
    $(".login").hide();
    $(".join").show();
    $(".tab-join").addClass("pure-tabs-item-selected");
    $(".tab-login").removeClass("pure-tabs-item-selected");
    return e.stopPropagation();
  });

  return $(".modal, .content").on("click", ".x-show-login", function(e) {
    $(".join").hide();
    $(".login").show();
    $(".tab-join").removeClass("pure-tabs-item-selected");
    $(".tab-login").addClass("pure-tabs-item-selected");
    return e.stopPropagation();
  });
};
